<template>
    <div class="card mb-3">
        <div class="card-header">
            <h5 class="fs-0 mb-0">Package Presets</h5>
        </div>
        <div class="card-body">
            <LoadingSpinner v-if="loading" class="my-5" />
            <LoadingError v-else-if="error" class="my-6" />
            <div v-else>
                <div v-if="presets.length" class="row">
                    <div v-for="(preset, index) in presets" :key="preset.id" class="col-md-4 mb-3 mb-md-0">
                        <div class="preset-box d-flex flex-row">
                            <div class="d-flex flex-column">
                                <span class="mb-2">{{ preset.display_name }}</span>
                                <small><strong>Length:</strong> {{ preset.length }} inches</small>
                                <small><strong>Width:</strong> {{ preset.width }} inches</small>
                                <small><strong>Height:</strong> {{ preset.height }} inches</small>
                            </div>
                            <div class="ml-auto" v-if="$auth.hasPermissions('mutate:package_dimensions')">
                                <div @click="deletePreset(index)">
                                    <i class="fa fa-times" style="cursor: pointer;" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else
                     class="d-flex align-items-center justify-content-center flex-column"
                     style="height: 300px;">
                    <i class="fas fa-info-circle" style="font-size: 2rem;" />
                    <span class="mt-3">No presets configured yet.</span>
                    <small class="mt-2 w-50 text-center">
                        Once you have added a package preset, they will appear in this section.
                    </small>
                </div>
                <hr v-if="$auth.hasPermissions('mutate:package_dimensions')">
                <form @submit.prevent="createPreset()"
                      class="d-flex flex-column"
                      v-if="$auth.hasPermissions('mutate:package_dimensions')">
                    <fieldset :disabled="creatingPreset" class="d-flex flex-row">
                        <div v-for="dimension in Object.keys(newPreset)"
                             :key="dimension"
                             class="form-group flex-fill mr-3">
                            <label :for="`${dimension}Input`">
                                {{ dimension.charAt(0).toUpperCase() + dimension.slice(1) }} (in)
                            </label>
                            <input :id="`${dimension}Input`"
                                   type="number"
                                   class="form-control"
                                   :class="{ 'is-invalid': creatingError }"
                                   min="1"
                                   step="1"
                                   max="200"
                                   @focus="newPreset[dimension] = null"
                                   @blur="newPreset[dimension] = newPreset[dimension] ? newPreset[dimension] : 0"
                                   v-model="newPreset[dimension]"
                                   required>
                        </div>
                    </fieldset>
                    <button class="btn btn-sm btn-falcon-primary mt-3 ml-auto" type="submit" :disabled="creatingPreset">
                        <span v-if="creatingPreset" class="spinner-border spinner-border-sm mr-2" />
                        {{ creatingPreset ? "Adding Preset" : "Add Preset" }}
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { createPackageDimensionsPreset, deletePackageDimensionsPreset, getPackageDimensionsPresets }
        from '../services/PackageDimensionsPresetService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';

    export default {
        name: 'PackagePresets',
        components: {
            LoadingSpinner,
            LoadingError,
        },
        data() {
            return {
                packagePresets: [],
                loading: true,
                error: null,
                presets: [],
                newPreset: {
                    length: 0,
                    width: 0,
                    height: 0,
                },
                creatingPreset: false,
                creatingError: false,
            };
        },
        watch: {
            newPreset: {
                handler() {
                    this.creatingError = false;
                },
                deep: true,
            },
        },
        async mounted() {
            try {
                const { data } = await getPackageDimensionsPresets();
                this.presets = data;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        methods: {
            async createPreset() {
                try {
                    this.creatingPreset = true;
                    if (this.presets.some(preset => (
                        preset.length === Number(this.newPreset.length)
                        && preset.width === Number(this.newPreset.width)
                        && preset.height === Number(this.newPreset.height)
                    ))) {
                        this.creatingError = true;
                        this.$alerts.danger('Preset Already Exists',
                                            'A preset with these dimensions already exists. Please enter different values and try again.');
                    } else {
                        const { data } = await createPackageDimensionsPreset(this.newPreset);
                        this.presets.push(data);
                        this.newPreset.length = this.newPreset.width = this.newPreset.height = 0;
                    }
                } catch (error) {
                    this.creatingError = true;
                    this.$alerts.danger('Failed To Create Preset', 'Please contact support if this problem persists.');
                } finally {
                    this.creatingPreset = false;
                }
            },
            async deletePreset(index) {
                const preset = this.presets[index];
                try {
                    await deletePackageDimensionsPreset(preset.id);
                    this.presets.splice(index, 1);
                } catch (error) {
                    this.$alerts.danger('Failed To Delete Preset', 'Please contact support if this problem persists.');
                }
            },
        },
    };
</script>

<style scoped>
    .preset-box {
        border: 1px solid #d8e2ef;
        transition: all .2s ease-in-out;
        display: block;
        padding: 1rem;
        border-radius: .25rem;
        margin-top: .5rem;
    }
</style>
